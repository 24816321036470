// src/shippingApi.ts
import axios from 'axios';
import FileSaver from 'file-saver';

export interface ShippingRateResponse {
  Service: string;
  Cost: number;
  InsuranceCost: number;
  ExpectedDeliveryDays: number | null; // Adjusted for possible null value
}

export interface ShippingResponse {
  Cost: number;
  InsuranceCost: number;
  LoadNumber: string;
  Carrier: string;
  SortingCode: string;
  BillOfLadingPdf: string | null;
  Packages: Package[];
}

export interface Package {
  Id: number;
  TrackingNumber: string[];
  LabelZpl: string;
  LabelBase64: string;
}

export interface ApiResponse {
  Data: ShippingResponse;
  IsSuccess: boolean;
  ErrorMessage: string | null;
}


interface ItemDetails {
    description: string;
    weight: number;
    weightType: number; // You may want to define a type for weight types (e.g., Kg, Lbs)
    hsCode: string;
    declaredValue: number;
    currency: string; // ISO currency code, e.g., USD, EUR
    quantity: number;
}

interface PackageDetails {
    description: string;
    sizeLength: number; // Length of the package
    sizeWidth: number; // Width of the package
    sizeHeight: number; // Height of the package
    sizeType: number; // You may want to define a type for size types (e.g., cm, inches)
    weight: number; // Weight of the package
    weightType: number; // You may want to define a type for weight types
    pieces: number; // Number of pieces in the package
    piecesType: string; // Type of pieces (e.g., boxes, bags)
    insuranceType: number; // You may want to define a type for insurance types
    insuranceDeclaredValue: number; // Declared value for insurance
    comment: string; // Any additional comments regarding the package
    items: ItemDetails[]; // Array of items contained in the package
}

interface ShippingDetails {
    idempotencyKey: string;
    service: string; // Service type for shipping (e.g., standard, express)
    carrier: string; // Carrier name (e.g., FedEx, UPS)
    reference1: string;
    reference2: string;
    reference3: string;
    reference4: string;
    pickup: string; // Pickup details or schedule
    from: string; // From location name
    fromContactName: string; // Contact name at the sender's location
    fromPhone: string; // Phone number at the sender's location
    fromEmail: string; // Email address at the sender's location
    fromAddress1: string; // Address line 1
    fromAddress2: string; // Address line 2
    fromAddress3: string; // Address line 3
    fromCity: string; // City of sender
    fromState: string; // State of sender
    fromPostalCode: string; // Postal code of sender
    fromCountry: string; // Country of sender
    fromTerminal: string; // Terminal details for the sender
    deliver: string; // Delivery instructions or details
    to: string; // To location name
    toContactName: string; // Contact name at the receiver's location
    toPhone: string; // Phone number at the receiver's location
    toEmail: string; // Email address at the receiver's location
    toAddress1: string; // Address line 1 of receiver
    toAddress2: string; // Address line 2 of receiver
    toAddress3: string; // Address line 3 of receiver
    toCity: string; // City of receiver
    toState: string; // State of receiver
    toPostalCode: string; // Postal code of receiver
    toCountry: string; // Country of receiver
    toTerminal: string; // Terminal details for the receiver
    instruction: string; // Additional shipping instructions
    signatureRequired: boolean; // Flag for signature requirement
    insuranceRequired: boolean; // Flag for insurance requirement
    packages: PackageDetails[]; // Array of package details
}
interface UserResponse {
    message: string; // Message indicating success or failure
    first_name?: string; // User's first name
    middle_name?: string; // User's middle name
    last_name?: string; // User's last name
    username?: string; // User's username
    password?: string; // User's password
    phone_number?: string; // User's mobile phone number
    email?: string; // User's email address
    address?: string; // User's address
    country?: string; // User's country
    state?: string; // User's state
    postal_code?: string; // User's postal code
}

  

interface PrintLabelRequest {
  LabelZpl: string;
}
interface EmailLabelRequest {
  LabelZpl: string;
  EmailAddress: string;
}

interface PrintAllLabelRequest {
  pkgs: Package[];
}

interface FindHsCodesRequest {
  description: string;
}

const RATE_URL = "http://34.71.103.121/shipments/rates"; 
const SHIPMENT_URL = "http://34.71.103.121/shipments";
const LABEL_URL = "http://34.71.103.121/shipments/printLabel";
const ALL_LABEL_URL = "http://34.71.103.121/shipments/printAllLabels";
const Newuser_URL = "http://34.71.103.121/user/newuser";
const API_URL = "http://localhost:8080/api/";
const EMAIL_URL = "http://34.71.103.121/shipments/emailLabel";
const FIND_HS_CODE_URL = "http://34.71.103.121/shipments/finsHsCodes";


// Function to fetch shipping rates
export const fetchShippingRates = async (shippingDetails: ShippingDetails): Promise<ShippingRateResponse | null> => {
  try {
    // Convert shippingDetails to a JSON string
    const shippingDetailsText = JSON.stringify(shippingDetails); // Convert to JSON string
    console.log(shippingDetailsText);
    const response = await fetch(RATE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set Content-Type to application/json
      },
      body: shippingDetailsText, // Send shipping details as JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);

    // Return relevant data
    return {
      Service:'Economy Air',
      Cost: data.Cost,
      InsuranceCost: data.InsuranceCost,
      ExpectedDeliveryDays: data.ExpectedDeliveryDays,
    };
  } catch (error) {
    console.error("Error fetching shipping rates:", error);
    return null; // Return null or handle the error accordingly
  }
};


export const createBooking = async (shippingDetails: ShippingDetails): Promise<ShippingResponse | null> => {
  try {
    console.log(shippingDetails);
    const response = await axios.post<ShippingResponse>(SHIPMENT_URL, shippingDetails);

    if (response.status !== 201) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log(response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error creating booking:", error.response?.data || error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    return null; 
  }
}

export const printLabel = async (labelZpl: string): Promise<string | null> => {
    try {
      const requestData: PrintLabelRequest = { LabelZpl: labelZpl };
      const response = await axios.post(LABEL_URL, requestData, {
        responseType: 'blob', 
      });
  
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
  
      // FileSaver.saveAs(blob, 'shipping-label.pdf');
      console.log('Label URL generated successfully.');
      return pdfUrl;
      console.log('Label printed successfully.');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error printing label:", error.response?.data || error.message);
      } else {
        console.error("Unexpected error:", error);
      }
      return null;
    }
};
  
  export const emailLabel = async (labelZpl: string, emailAddress: string): Promise<boolean | null> => {
    try {
      const requestData: EmailLabelRequest = { LabelZpl: labelZpl, EmailAddress: emailAddress };
      const response = await axios.post(EMAIL_URL, requestData);
  
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('Label emailed successfully.');
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error emailing label:", error.response?.data || error.message);
      } else {
        console.error("Unexpected error:", error);
      }
      return null;
    }
  };
  

  
export const printAllLabels = async (pkgs: Package[]): Promise<string | null> => {
  try {
    const response = await axios.post(ALL_LABEL_URL, pkgs, {
      responseType: 'blob', 
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(blob);

    // FileSaver.saveAs(blob, 'shipping-label.pdf');
    console.log('Label URL generated successfully.');
    return pdfUrl;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error printing label:", error.response?.data || error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    return null;
  }
};


  export const findHsCode = async (description: string): Promise<string | null> => {
    try {
      const requestData: FindHsCodesRequest = { description };
      const response = await axios.post(FIND_HS_CODE_URL, requestData);
  
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = response.data;
  
      // Assuming your backend returns the HS code in the first element of the response array
      if (responseData && Array.isArray(responseData) && responseData.length > 0) {
        const hsCode = responseData[0][0];
        if (hsCode === "No HS Code found") {
          console.log('No HS Code found for the provided description.');
          return null;
        }
        console.log('HS Code found:', hsCode);
        return hsCode;
      }
  
      // If no response data found
      console.log('No valid response from server.');
      return null;
    } catch (error) {
      // Handling errors properly
      if (axios.isAxiosError(error)) {
        console.error("Error finding HS Code:", error.response?.data || error.message);
      } else {
        console.error("Unexpected error:", error);
      }
      return null;
    }
  };

export const postNewUser = async (newUserDetails: any): Promise<UserResponse | null> => {
    try {
      console.log(newUserDetails); // Log the user details being sent
      const response = await fetch(Newuser_URL, {
        method: "POST", // HTTP method
        headers: {
          "Content-Type": "application/json", // Set Content-Type to application/json
        },
        body: JSON.stringify(newUserDetails), // Convert newUserDetails to JSON string
      });
  
      // Check if the response is OK (status code in the range 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`); // Handle non-2xx responses
      }
  
      const data = await response.json(); // Parse JSON from the response
      console.log(data); // Log the response data
  
      return data; // Return the response data
    } catch (error) {
      // Handle errors appropriately
      console.error("Error creating user:", error);
      return null; // Return null or handle the error accordingly
    }
  };
  

  
  // Function to verify user existence based on username and mobile phone
  export const verifyUser = async (username: string, mobile_phone: string): Promise<any> => {
    try {
      const response = await axios.post(`${API_URL}/verify-user`, {
        username,
        mobile_phone,
      });
      return response.data; // Adjust as needed based on your API response
    } catch (error) {
      console.error('Error verifying user:', error);
      throw error; // Rethrow the error for handling in the calling function
    }
  };
  
  // Function to reset the password
  export const resetPassword = async (username: string, newPassword: string): Promise<any> => {
    try {
      const response = await axios.put(`${API_URL}/reset-password`, {
        username,
        newPassword,
      });
      return response.data; // Adjust as needed based on your API response
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error; // Rethrow the error for handling in the calling function
    }
  };
  
