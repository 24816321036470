import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { Country, State, ICountry, IState } from 'country-state-city';
import { postNewUser } from '../API/shippingApi'; // Ensure to import the postNewUser function

const NewUser: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // Corrected variable name
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [states, setStates] = useState<IState[]>([]);
  const [postalCode, setPostalCode] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setCountry('');
    setState('');
    setStates([]);
    setPostalCode('');
    setUsername('');
    setPassword('');
    setEmail('');
    setPhoneNumber('');
    setAddress('');
    setErrorMessage(''); // Clear error message
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryCode = e.target.value;
    setCountry(selectedCountryCode);
    const countryStates: IState[] = State.getStatesOfCountry(selectedCountryCode);
    setStates(countryStates);
    setState(''); // Reset state when country changes
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(e.target.value);
  };

  const handleSave = async () => {
    const now = new Date();
  
    const newUser = {
      firstName,
      middleName,
      lastName,
      username,
      password,
      phoneNumber,
      email,
      address,
      country,
      state,
      postalCode,
    };
  
    try {
      const response = await postNewUser(newUser); // Call the API service to post the new user
      if (response && response.message === 'User created successfully') {
        console.log('New user saved:', response);
        handleCloseModal(); // Close modal on success
      } else {
        setErrorMessage(response ? response.message : 'User creation failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while saving the user');
    }
  };
  
  return (
    <>
      <Button onClick={handleOpenModal}>New User</Button>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorMessage && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <Flex direction="column" align="center">
              <Box bg="white" p={6} borderRadius="md" boxShadow="md" width="100%" maxWidth="400px">
                <Flex flexDirection="column">
                  <Text mb={2}>First Name:</Text>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    mb={4}
                  />
                  <Text mb={2}>Middle Name:</Text>
                  <Input
                    type="text"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                    mb={4}
                  />
                  <Text mb={2}>Last Name:</Text>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    mb={4}
                  />
                  <Text mb={2}>Phone Number:</Text>
                  <Input
                    type="text" 
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    mb={4}
                  />
                  <Text mb={2}>Email:</Text>
                  <Input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    mb={4}
                  />
                  <Text mb={2}>Address:</Text>
                  <Input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    mb={4}
                  />
                  {/* Address Fields */}
                  <Text mb={2}>Country:</Text>
                  <Select
                    placeholder="Select country"
                    onChange={handleCountryChange}
                    mb={4}
                    value={country}
                  >
                    {Country.getAllCountries().map((country: ICountry) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </Select>

                  <Text mb={2}>State:</Text>
                  <Select
                    placeholder="Select state"
                    onChange={handleStateChange}
                    mb={4}
                    value={state}
                    isDisabled={!country} // Disable state dropdown if no country is selected
                  >
                    {states.map((state: IState) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </Select>

                  <Text mb={2}>Postal Code:</Text>
                  <Input
                    type="text"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    mb={4}
                  />

                  <Text mb={2}>Username:</Text>
                  <Input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    mb={4}
                  />
                  <Text mb={2}>Password:</Text>
                  <Input
                    type="password" // Changed to password type
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    mb={4}
                  />
                </Flex>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSave} colorScheme="green">Save</Button>
            <Button colorScheme="blue" onClick={handleCloseModal}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewUser;
