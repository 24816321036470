import React from 'react';
import './Css/ShippingService.css'; // Ensure to use the same CSS file for consistent styling

// Import logos for each step
import integrateLogo from '../assets/images/integrate.jpg'; // Replace with the actual path
import payPrintLogo from '../assets/images/pay-print.jpg'; // Replace with the actual path
import dropOffLogo from '../assets/images/drop-off.jpg'; // Replace with the actual path

// Define the type for process steps
interface ProcessStep {
    title: string;
    description: string;
    logo: string; // Add logo property
}

const HowItWorks: React.FC = () => {
    const processSteps: ProcessStep[] = [
        {
            title: 'Integrate Your Online Store',
            description: 'Upload credit to your account, select and review the packages you’re preparing, then print your labels within your account – all done in a few clicks!',
            logo: integrateLogo, // Use the imported logo
        },
        {
            title: 'Pay and Print Your Shipping Labels',
            description: 'Upload credit to your account, select and review the packages you’re preparing, then print your labels within your account – all done in a few clicks!',
            logo: payPrintLogo, // Use the imported logo
        },
        {
            title: 'Drop Off or Arrange for Pick-up',
            description: 'Visit our branches or drop-off points near you, or alternatively, book our pick-up service for your convenience.',
            logo: dropOffLogo, // Use the imported logo
        },
    ];

    return (
        <div className="shipping-service">
            <h2 style={{
                fontSize: '2em',    
                fontWeight: 'bold',    
                textAlign: 'center',     
                color: '#333',            
                marginBottom: '20px',     
                textTransform: 'uppercase',
                letterSpacing: '1px',     
            }}>
                How Fly My Package Works
            </h2>
            <div className="shipping-options">
                {processSteps.map((step, index) => (
                    <div key={index} className="shipping-option">
                        <img src={step.logo} alt={`${step.title} logo`} className="shipping-logo" />
                        <h3>{step.title}</h3>
                        <p>{step.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HowItWorks;
