import React from 'react';
import { Box, Text, Flex, Button, VStack, Icon } from '@chakra-ui/react';
import { FaTruck, FaDollarSign, FaShieldAlt, FaCalendarCheck } from 'react-icons/fa';

interface ShippingRateResponse {
  Service: string;
  Cost: number;
  InsuranceCost: number;
  ExpectedDeliveryDays: number | null;
}

interface CurrentStep4Props {
  shippingRate: ShippingRateResponse | null;
  handleCreateBooking: () => void;  
}

const CurrentStep4: React.FC<CurrentStep4Props> = ({ shippingRate, handleCreateBooking }) => {
  if (!shippingRate) {
    return <Text color="red.500">No shipping rate details available.</Text>;
  }

  return (
    <Box 
      borderWidth="1px" 
      borderRadius="md" 
      p={6} 
      mt={6} 
      boxShadow="lg" 
      backgroundColor="white"
    >
      <VStack spacing={4} align="stretch">
        <Text fontSize="2xl" fontWeight="bold" color="teal.600" textAlign="center">
          Shipping Rate Details
        </Text>
        
        <Flex alignItems="center">
          <Icon as={FaTruck} color="teal.500" boxSize={5} mr={2} />
          <Text><strong>Service:</strong> {shippingRate.Service}</Text>
        </Flex>
        
        <Flex alignItems="center">
          <Icon as={FaDollarSign} color="green.500" boxSize={5} mr={2} />
          <Text><strong>Cost:</strong> ${shippingRate.Cost.toFixed(2)}</Text>
        </Flex>

        <Flex alignItems="center">
          <Icon as={FaShieldAlt} color="blue.500" boxSize={5} mr={2} />
          <Text><strong>Insurance Cost:</strong> ${shippingRate.InsuranceCost.toFixed(2)}</Text>
        </Flex>

        <Flex alignItems="center">
          <Icon as={FaCalendarCheck} color="orange.500" boxSize={5} mr={2} />
          <Text>
            <strong>Expected Delivery Days:</strong> {shippingRate.ExpectedDeliveryDays ?? 'N/A'}
          </Text>
        </Flex>
        
        <Flex justifyContent="center" mt={4}>
          <Button 
            colorScheme="teal" 
            size="lg" 
            leftIcon={<FaTruck />} 
            onClick={handleCreateBooking}
          >
            Book Now
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default CurrentStep4;
