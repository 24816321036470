// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSExbLpsC00yNYpN95LhKsSIufEK3ePz4",
  authDomain: "gtaramp.firebaseapp.com",
  projectId: "gtaramp",
  storageBucket: "gtaramp.appspot.com",
  messagingSenderId: "493622138867",
  appId: "1:493622138867:web:5bca4d3de2ef5aea3d6486",
  measurementId: "G-KRC8KY4Y1W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app);