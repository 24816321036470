import React from 'react';
import logo from './logo.svg';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ShippingService from './Components/ShippingService';
import HowItWorks from './Components/HowItWorks';
import Shipping from './Components/Shipping';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider>

    <div>
<Header />
<ShippingService />
<HowItWorks />
<Footer />
    </div>
    </ChakraProvider>

  );
}

export default App;
