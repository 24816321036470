import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { auth } from '../firebase.config.js';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { verifyUser, resetPassword } from '../API/shippingApi'; // Ensure to import the postNewUser function

const Respass: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [mobile_phone, setMobilePhone] = useState('');
  const [showResetModal, setShowResetModal] = useState(false);
  const [auto_generated_password, setNewPassword] = useState('');
  const [confirmauto_generated_password, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState<string[]>(Array(6).fill('')); // Assuming 6 digits OTP
  const [currentStep, setCurrentStep] = useState(1);

  // Load username from local storage when component mounts
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const handleLoginModalOpen = () => setIsOpen(true);
  const handleLoginModalClose = () => {
    setIsOpen(false);
    setUsername('');
    setMobilePhone('');
    setErrorMessage('');
    setSuccessMessage('');
    setPhoneNumber('');
    setOtp(Array(6).fill('')); // Reset OTP
    setCurrentStep(1); // Reset to step 1
    setShowResetModal(false); // Also reset the showResetModal
  };

  const handleResetPassword = async () => {
    const storedUsername = localStorage.getItem('username');
    if (!storedUsername) {
      setErrorMessage('Username is missing. Please try verifying again.');
      return;
    }
  
    if (auto_generated_password !== confirmauto_generated_password) {
      setErrorMessage('Passwords do not match.');
      return;
    }
  
    try {
      const response = await resetPassword(storedUsername, auto_generated_password);
  
      const text = await response.data;
      if (response.status === 200) {
        setSuccessMessage('Password successfully reset.');
        setShowResetModal(false);
        console.log(successMessage);
        localStorage.removeItem('username'); // Remove username from local storage
      } else {
        setErrorMessage(`Failed to reset password: ${text}`);
      }
    } catch (error) {
      console.error('Failed to reset password:', error);
      setErrorMessage('Failed to reset password. Please try again later.');
    }
  };

  const OnCaptchaVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth, 'recaptcha-container',
        {
          size: 'invisible',
          callback: () => {
            onSignUp();
          },
          'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
          },
        },
      );
    }
  };


  const onSignUp = () => {
   // OnCaptchaVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPhone = '+' + phoneNumber;

    signInWithPhoneNumber(auth, formatPhone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        // alert('OTP Sent Successfully');
      })
      .catch((error) => {
        alert('Error: ' + error.message);
      });
  };

  const handleVerifyOtp = async () => {
     window.confirmationResult.confirm(otp)
    .then(() => {
      alert('OTP Verified Successfully');
      handleResetPassword();
    })
  };

  return (
    <>
      <Button onClick={handleLoginModalOpen}>Reset Password</Button>
      <Modal isOpen={isOpen} onClose={handleLoginModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentStep === 1 && (
              <>
                <Text mb={2}>Username:</Text>
                <Input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  mb={4}
                  placeholder="Enter your username"
                />
                <Text textAlign="center" mb={4}>
                  Enter your phone number
                </Text>
                <FormControl mb={4}>
                  <FormLabel>Phone Number</FormLabel>
                  <PhoneInput
                    country={'ca'}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    inputStyle={{ width: '100%' }}
                  />
                  <div id="recaptcha-container"></div>
                </FormControl>
                <Button colorScheme="teal" onClick={onSignUp}>
                  Send OTP
                </Button>
                {errorMessage && <Text color="red.500">{errorMessage}</Text>}
              </>
            )}
            {currentStep === 2 && (
              <>
                <Text textAlign="center" mb={4}>
                  Enter the OTP sent to your phone.
                </Text>
                <Flex justify="center" gap={4}>
                  {otp.map((digit, index) => (
                    <Input
                      key={index}
                      size="sm"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => {
                        const value = e.target.value;
                        setOtp((prev) => {
                          const newOtp = [...prev];
                          newOtp[index] = value;
                          return newOtp;
                        });
                      }}
                      textAlign="center"
                    />
                  ))}
                </Flex>
                <Button colorScheme="green" onClick={handleVerifyOtp}>
                  Verify OTP
                </Button>
                {errorMessage && <Text color="red.500">{errorMessage}</Text>}
                {successMessage && <Text color="green.500">{successMessage}</Text>}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleLoginModalClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Reset Password Modal */}
      <Modal isOpen={showResetModal} onClose={() => setShowResetModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set New Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" align="center">
              <Text fontSize="2xl" fontWeight="bold" mb={4}>Enter your new password</Text>
              <Box
                bg="white"
                p={6}
                borderRadius="md"
                boxShadow="md"
                width="100%"
                maxWidth="400px"
              >
                <Flex flexDirection="column">
                  <Text mb={2}>New Password:</Text>
                  <Input
                    type="password"
                    value={auto_generated_password}
                    onChange={(e) => setNewPassword(e.target.value)}
                    mb={4}
                    placeholder="Enter new password"
                  />
                  <Text mb={2}>Confirm Password:</Text>
                  <Input
                    type="password"
                    value={confirmauto_generated_password}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    mb={4}
                    placeholder="Confirm new password"
                  />
                  <Text color="gray.500" mb={4}>
                    Password should be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.
                  </Text>
                  {errorMessage && <Text color="red.500" mb={4}>{errorMessage}</Text>}
                  <Flex justifyContent="space-between" mb={4}>
                    <Button onClick={handleResetPassword} colorScheme="blue">
                      Submit
                    </Button>
                    <Button onClick={() => setShowResetModal(false)}>Cancel</Button>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Respass;
