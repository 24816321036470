import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack,
  Grid,
  VStack,
  Stack,
  Heading,  
  CircularProgress,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { auth } from '../firebase.config.js';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { Toaster } from 'react-hot-toast';
import CurrentStep4 from './CurrentStep4';
import { fetchShippingRates, createBooking, printLabel, emailLabel, findHsCode, printAllLabels } from '../API/shippingApi'; // Adjust the import path as necessary
import Respass from './Respass';
import NewUser from './NewUser';

import { useEffect } from 'react';
// Utility function to convert camelCase to PascalCase
const camelToPascal = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(camelToPascal);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((result: Record<string, any>, key: string) => {
      // Capitalize the first letter
      const pascalKey = key.charAt(0).toUpperCase() + key.slice(1);
      result[pascalKey] = camelToPascal(obj[key]);
      return result;
    }, {});
  }
  return obj;
};

declare global {
  interface Window {
    recaptchaVerifier?: any;
    confirmationResult?: any;
  }
}

export interface ShippingRateResponse {
  Service: string;
  Cost: number;
  InsuranceCost: number;
  ExpectedDeliveryDays: number | null; // Adjusted for possible null value
}
interface ItemDetails {
  description: string;
  weight: number;
  weightType: number; // You may want to define a type for weight types (e.g., Kg, Lbs)
  hsCode: string;
  declaredValue: number;
  currency: string; // ISO currency code, e.g., USD, EUR
  quantity: number;
}

interface PackageDetails {
  description: string;
  sizeLength: number; // Length of the package
  sizeWidth: number; // Width of the package
  sizeHeight: number; // Height of the package
  sizeType: number; // You may want to define a type for size types (e.g., cm, inches)
  weight: number; // Weight of the package
  weightType: number; // You may want to define a type for weight types
  pieces: number; // Number of pieces in the package
  piecesType: string; // Type of pieces (e.g., boxes, bags)
  insuranceType: number; // You may want to define a type for insurance types
  insuranceDeclaredValue: number; // Declared value for insurance
  comment: string; // Any additional comments regarding the package
  items: ItemDetails[]; // Array of items contained in the package
}

interface ShippingDetails {
  idempotencyKey: string;
  service: string; // Service type for shipping (e.g., standard, express)
  carrier: string; // Carrier name (e.g., FedEx, UPS)
  reference1: string;
  reference2: string;
  reference3: string;
  reference4: string;
  pickup: string; // Pickup details or schedule
  from: string; // From location name
  fromContactName: string; // Contact name at the sender's location
  fromPhone: string; // Phone number at the sender's location
  fromEmail: string; // Email address at the sender's location
  fromAddress1: string; // Address line 1
  fromAddress2: string; // Address line 2
  fromAddress3: string; // Address line 3
  fromCity: string; // City of sender
  fromState: string; // State of sender
  fromPostalCode: string; // Postal code of sender
  fromCountry: string; // Country of sender
  fromTerminal: string; // Terminal details for the sender
  deliver: string; // Delivery instructions or details
  to: string; // To location name
  toContactName: string; // Contact name at the receiver's location
  toPhone: string; // Phone number at the receiver's location
  toEmail: string; // Email address at the receiver's location
  toAddress1: string; // Address line 1 of receiver
  toAddress2: string; // Address line 2 of receiver
  toAddress3: string; // Address line 3 of receiver
  toCity: string; // City of receiver
  toState: string; // State of receiver
  toPostalCode: string; // Postal code of receiver
  toCountry: string; // Country of receiver
  toTerminal: string; // Terminal details for the receiver
  instruction: string; // Additional shipping instructions
  signatureRequired: boolean; // Flag for signature requirement
  insuranceRequired: boolean; // Flag for insurance requirement
  packages: PackageDetails[]; // Array of package details
}

interface ShippingResponse {
  Cost: number;
  InsuranceCost: number;
  LoadNumber: string;
  BillOfLadingPdf: string | null;
  Carrier: string;
  SortingCode: string;
  Packages: Package[];
}

interface Package {
  Id: number;
  TrackingNumber: string[];
  LabelZpl: string;
  LabelBase64: string;
}


const ShippingForm: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState(''); 
  const [shippingRate, setShippingRate] = useState<ShippingRateResponse | null>(null); 
  const [showShippingRates, setShowShippingRates] = useState(false); 
  const [bookingData, setBookingData] = useState<ShippingResponse | null>(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [packageCount, setPackageCount] = useState<number>(0);
  const [isGuest, setIsGuest] = useState(false); // State to track guest status
  const [loading, setLoading] = useState(false);
  const [expandedPackages, setExpandedPackages] = useState<{ [key: number]: boolean }>({});

  // SMS Verification States
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const otpLength = 6;
  const [defaultShippingDetails, setDefaultShippingDetails] = useState<ShippingDetails>({
    idempotencyKey: '', 
    service: '', 
    carrier: '', 
    reference1: '', 
    reference2: '', 
    reference3: '', 
    reference4: '',
    pickup: '', 
    from: '', 
    fromContactName: '', 
    fromPhone: '', 
    fromEmail: '', 
    fromAddress1: '',
    fromAddress2: '', 
    fromAddress3: '', 
    fromCity: '', 
    fromState: '', 
    fromPostalCode: '', 
    fromCountry: '', 
    fromTerminal: 'MISS,UTOR,BRA', 
    deliver: '',
    to: '', 
    toContactName: '', 
    toPhone: '', 
    toEmail: '', 
    toAddress1: '', 
    toAddress2: '', 
    toAddress3: '', 
    toCity: '', 
    toState: '', 
    toPostalCode: '', 
    toCountry: '', 
    toTerminal: '', 
    instruction: '', 
    signatureRequired: false, 
    insuranceRequired: false, 
    packages: [
      
    ],
  });
  const [shippingDetails, setShippingDetails] = useState<ShippingDetails>({
    idempotencyKey: '', 
    service: '', 
    carrier: '', 
    reference1: '', 
    reference2: '', 
    reference3: '', 
    reference4: '',
    pickup: '', 
    from: '', 
    fromContactName: '', 
    fromPhone: '', 
    fromEmail: '', 
    fromAddress1: '',
    fromAddress2: '', 
    fromAddress3: '', 
    fromCity: '', 
    fromState: '', 
    fromPostalCode: '', 
    fromCountry: '', 
    fromTerminal: 'MISS,UTOR,BRA', 
    deliver: '',
    to: '', 
    toContactName: '', 
    toPhone: '', 
    toEmail: '', 
    toAddress1: '', 
    toAddress2: '', 
    toAddress3: '', 
    toCity: '', 
    toState: '', 
    toPostalCode: '', 
    toCountry: '', 
    toTerminal: '', 
    instruction: '', 
    signatureRequired: false, 
    insuranceRequired: false, 
    packages: [
      
    ],
  });

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);

  useEffect(() => {
    // Check if the page has already been refreshed
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');

    if (!hasRefreshed) {
      // If not refreshed, set the flag and reload the page
      sessionStorage.setItem('hasRefreshed', 'true');
      window.location.reload();
    }
  }, []);

  // Validation Function
  const validateFields = (validatePackage : boolean): boolean => {
    const errors: { [key: string]: string } = {};
  
    // From (Sender) Fields Validation
    if (!shippingDetails.fromContactName) {
      errors.fromContactName = 'Sender name is required';
    }
    if (!shippingDetails.fromAddress1) {
      errors.fromAddress1 = 'Sender address is required';
    }
    if (!shippingDetails.fromCity) {
      errors.fromCity = 'City of origin is required';
    }
    if (!shippingDetails.fromState) {
      errors.fromState = 'State of origin is required';
    }
    if (!shippingDetails.fromPostalCode) {
      errors.fromPostalCode = 'Postal code of origin is required';
    }
    if (!shippingDetails.fromCountry) {
      errors.fromCountry = 'Country of origin is required';
    }
  
    // To (Receiver) Fields Validation
    if (!shippingDetails.toContactName) {
      errors.toContactName = 'Receiver name is required';
    }
    if (!shippingDetails.toAddress1) {
      errors.toAddress1 = 'Receiver address is required';
    }
    if (!shippingDetails.toCity) {
      errors.toCity = 'City of destination is required';
    }
    if (!shippingDetails.toState) {
      errors.toState = 'State of destination is required';
    }
    if (!shippingDetails.toPostalCode) {
      errors.toPostalCode = 'Postal code of destination is required';
    }
    if (!shippingDetails.toCountry) {
      errors.toCountry = 'Country of destination is required';
    }
  
    // Package Details Validation
    if(validatePackage){
      shippingDetails.packages.forEach((pkg, index) => {
        if (pkg.sizeLength <= 0) {
          errors[`package${index}_sizeLength`] = `Package ${index + 1}: Length must be greater than zero`;
        }
        if (pkg.sizeWidth <= 0) {
          errors[`package${index}_sizeWidth`] = `Package ${index + 1}: Width must be greater than zero`;
        }
        if (pkg.sizeHeight <= 0) {
          errors[`package${index}_sizeHeight`] = `Package ${index + 1}: Height must be greater than zero`;
        }
        if (pkg.weight <= 0) {
          errors[`package${index}_weight`] = `Package ${index + 1}: Weight must be greater than zero`;
        }
        if (pkg.pieces <= 0) {
          errors[`package${index}_pieces`] = `Package ${index + 1}: Pieces must be greater than zero`;
        }
      });
    }

    setValidationErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  
  const addNewPackage = () => {
    const newPackage = {
      description: '',
      sizeLength: 15.0,
      sizeWidth: 15.0,
      sizeHeight: 15.0,
      sizeType: 1,
      weight: 0,
      weightType: 1,
      pieces: 1,
      piecesType: 'Box',
      insuranceType: 0,
      insuranceDeclaredValue: 0,
      comment: '',
      items: [
        {
          description: '',
          weight: 0,
          weightType: 1,
          hsCode: '',
          declaredValue: 0,
          currency: '',
          quantity: 0,
        },
      ],
    };
  
    setShippingDetails((shippingDetails) => ({
      ...shippingDetails,
      packages: [...shippingDetails.packages, newPackage],
    }));
  };

  const handlePackageCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = parseInt(e.target.value) || 1;
    setPackageCount(count);
    const newPackages = Array.from({ length: count }, () => ({
      description: '',
      sizeLength: 15.0,
      sizeWidth: 15.0,
      sizeHeight: 15.0,
      sizeType: 1,
      weight: 0,
      weightType: 1,
      pieces: 1,
      piecesType: 'Box',
      insuranceType: 0,
      insuranceDeclaredValue: 0,
      comment: '',
      items: [
        {
          description: '',
          weight: 0,
          weightType: 1,
          hsCode: '',
          declaredValue: 0,
          currency: '',
          quantity: 0,
        },
      ],
    }));
    setShippingDetails({ ...shippingDetails, packages: newPackages });
  };

  const handlePackageInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number, field: keyof PackageDetails) => {
    const updatedPackages = [...shippingDetails.packages];
    updatedPackages[index] = { ...updatedPackages[index], [field]: e.target.value };
    setShippingDetails({ ...shippingDetails, packages: updatedPackages });
  };

  const handleItemInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    packageIndex: number,
    itemIndex: number,
    field: keyof ItemDetails
  ) => {
    const updatedPackages = [...shippingDetails.packages];
    const updatedItems = [...updatedPackages[packageIndex].items];
    updatedItems[itemIndex] = { ...updatedItems[itemIndex], [field]: e.target.value };
    updatedPackages[packageIndex].items = updatedItems;
    setShippingDetails({ ...shippingDetails, packages: updatedPackages });
  };

  const addItemToPackage = (packageIndex: number) => {
    const updatedPackages = [...shippingDetails.packages];
    updatedPackages[packageIndex].items.push({
      description: '',
      weight: 0,
      weightType: 1,
      hsCode: '',
      declaredValue: 0,
      currency: 'CAD',
      quantity: 0,
    });
    setShippingDetails({ ...shippingDetails, packages: updatedPackages });
  };


  const handlePackageSubmit = async () => {
    if (validateFields(true)) {
      const snakeCaseDetails = camelToPascal(shippingDetails);
      const rates = await fetchShippingRates(snakeCaseDetails);
      if (rates) {
        setShippingRate(rates);
        setShowShippingRates(true);
        console.log(shippingDetails);

      }
    } else {
      alert('Correct the errors');
    }
  };

  const handleCreateBooking = async () => {
    shippingDetails.from = shippingDetails.fromContactName;
    shippingDetails.to= shippingDetails.toContactName;
    const snakeCaseDetails = camelToPascal(shippingDetails);
    const booking = await createBooking(snakeCaseDetails);
    setBookingData(booking);
    setCurrentStep(6);
    console.log(booking);
  }

  const handlePrintBarcode = async (LabelZpl: string) => {
    try {
      const pdfUrl = await printLabel(LabelZpl);
      if (pdfUrl) {
        window.open(pdfUrl, '_blank');
        setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
      } else {
        console.error('Failed to generate PDF URL.');
      }
    } catch (error) {
      console.error('Error while printing barcode:', error);
    }
  };

  const handlePrintAllBarcodes = async (pkgs: Package[]) => {
    try {
      const pdfUrl = await printAllLabels(pkgs);
      if (pdfUrl) {
        window.open(pdfUrl, '_blank');
        setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
      } else {
        console.error('Failed to generate PDF URL.');
      }
      console.log(pkgs);
    } catch (error) {
      console.error('Error while printing barcode:', error);
    }
  };

  const handleEmailBarcode = async (LabelZpl: string, emailAddress: string) => {
    try {
      const response = await emailLabel(LabelZpl, emailAddress);
      
    } catch (error) {
      console.error('Error sending barcode email:', error);
      alert('An error occurred while sending the email.');
    }
  };

  const toggleExpanded = (packageIndex: number) => {
    setExpandedPackages((prevExpanded) => ({
      ...prevExpanded,
      [packageIndex]: !prevExpanded[packageIndex], 
    }));
  };

  const onEmailBarcodeClick = (LabelZpl: string) => {
    setSelectedLabel(LabelZpl);
    setIsEmailModalOpen(true);
  };

  const handleSendEmails = () => {
    if (selectedLabel && emailInput) {
      handleEmailBarcode(selectedLabel, emailInput);
      setIsEmailModalOpen(false);
      setEmailInput(''); 
    }
  };

  const handleFindHsCode = async (description: string, packageIndex: number, itemIndex: number) => {
    setLoading(true);
    let hsCode = await findHsCode(description);
    if (hsCode) {
      hsCode = hsCode.replace(/[\[\]\.]/g, '');
      console.log(`The HS Code for "${description}" is: ${hsCode}`);
      // Update the hsCode field for the specific item
      const updatedPackages = [...shippingDetails.packages];
      const updatedItems = [...updatedPackages[packageIndex].items];
      updatedItems[itemIndex] = { ...updatedItems[itemIndex], hsCode };
      updatedPackages[packageIndex].items = updatedItems;
      setShippingDetails({ ...shippingDetails, packages: updatedPackages });
    } else {
      console.log('No HS Code could be found.');
    }
    setLoading(false);
  };

  const OnCaptchaVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth, 'recaptcha-container',
        {
          size: 'invisible',
          callback: () => {
            onSignUp();
          },
          'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
          },
        },
      );
    }
  };

  const onSignUp = () => {
    OnCaptchaVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPhone = '+' + phoneNumber;

    signInWithPhoneNumber(auth, formatPhone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        // alert('OTP Sent Successfully');
        setCurrentStep(2); // Move to OTP verification step
      })
      .catch((error) => {
        alert('Error: ' + error.message);
      });
  };

  const handleLogin = () => {
    // Add authentication logic here
    // If successful, proceed to the next step
    setIsGuest(false); // Mark the user as a guest

    setCurrentStep(1);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;

    // Allow only digits
    if (/^[0-9]*$/.test(value) && (value.length <= 1)) {
      const newOtp = otp.split('');
      newOtp[index] = value;
      setOtp(newOtp.join(''));

      // Move to next input
      if (value && index < otpLength - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };
  const handleShippingInputChange = (event: any, field: any) => {
    const { value } = event.target;

    // Update the shippingDetails state based on the field name
    setShippingDetails((prevDetails) => ({
      ...prevDetails,
      [field]: field === 'SignatureRequired' || field === 'InsuranceRequired' ? value === 'Yes' : value,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const previousInput = document.getElementById(`otp-input-${index - 1}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handleVerifyOtp = () => {
    // window.confirmationResult.confirm(otp)
    // .then(() => {
    //   alert('OTP Verified Successfully');
    // setCurrentStep(3); // Move to step 3 to ask for package details

    // })
    if (isGuest) {
      setCurrentStep(4); // Move to step 4 if the user is a guest
    } else {
      setCurrentStep(3); // Move to step 3 if the user is not a guest
    }
  };

  const handlePreviousShipments = () => {

  };
  const handleGuest = () => {
    setIsGuest(true); // Mark the user as a guest
    setCurrentStep(1); // Set the current step to 1
  };

  // Method to handle starting a new shipment
  const handleNewShipment = () => {
    setCurrentStep(4);
  };
  const getModalSize = () => {
    switch (currentStep) {
      case 0:
        return { maxWidth: "630px", maxHeight: "850px" };
      case 3:
        return { maxWidth: "630px", maxHeight: "950px" };
      case 4:
        return { maxWidth: "1400px", maxHeight: "950px" };
      case 5:
        return { maxWidth: "1400px", maxHeight: "auto" };
      case 6:
        return { maxWidth: "1200px", maxHeight: "900px" };
      default:
        return { maxWidth: "500px", maxHeight: "330px" };
    }
  };

  const handleClickNext= () => {
    if (isGuest && currentStep === 2) {                                
      setCurrentStep(4);
    } else {
      if (currentStep === 4 ) {
        if (validateFields(false)) {
          setCurrentStep((prev) => prev + 1);
        } else {
          alert('Fill the required Fields');
        }
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  const resetBookingDetails = () => {
    setShippingDetails(defaultShippingDetails);
    setShippingRate(null); 
    setCurrentStep(4); 
    setBookingData(null); 
    setValidationErrors({});
    setExpandedPackages([]);
    setShowShippingRates(false);
  };
  


  return (
    <Box>
      <Button onClick={() => { setIsOpen(true); }}>Ship Now</Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ModalOverlay />
        <ModalContent {...getModalSize()}> {/* Apply sizing here */}
          <ModalHeader
            fontWeight="bold" // Makes the header bold
            fontSize="2xl" // Larger font size for emphasis
            color="teal.600" // Change text color to teal for a fresh look
            textAlign="center" // Center aligns the text
            borderBottomWidth="2px" // Adds a bottom border for separation
            borderColor="teal.300" // Sets the border color
            pb={4} // Adds padding below the header
            letterSpacing="wider" // Wider letter spacing for readability
          >
            Shipping Form
          </ModalHeader>
          <ModalBody>
            {currentStep === 0 && (
              <>
                <ModalHeader>Login</ModalHeader>
                <ModalBody>
                  <Flex justifyContent="space-between" width="100%">

                    <Input
                      placeholder='Username'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <Input
                      placeholder="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Flex>
                  <ModalFooter>


                    <Flex justifyContent="space-between" width="100%">
                      <Button colorScheme="blue" onClick={handleLogin}>
                        Login
                      </Button>
                      <Respass />
                      <NewUser />
                      <Button colorScheme="blue" onClick={handleGuest}>
                        Guest
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalBody>
              </>


            )}
            {currentStep === 1 && (
              <>
                <Text
                  textAlign="center"
                  mb={4}
                  fontWeight="bold" // Makes the text bold
                  fontSize="lg" // Larger font size for emphasis
                  color="teal.500" // Change text color to teal
                  textShadow="1px 1px 2px rgba(0, 0, 0, 0.3)" // Adds a subtle shadow for depth
                  padding="2" // Add padding around the text
                  borderWidth="1px" // Border width for emphasis
                  borderColor="teal.300" // Border color
                  borderRadius="md" // Medium rounded corners
                  backgroundColor="gray.50" // Light gray background for contrast
                  letterSpacing="wider" // Wider letter spacing for readability
                >
                  Enter Your Phone Number
                </Text>

                <FormControl mb={4}>
                  <FormLabel>Phone Number</FormLabel>
                  <PhoneInput
                    country={'ca'}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    inputStyle={{ width: '100%' }}
                  />
                  <div id="recaptcha-container"></div>

                </FormControl>
                <Button colorScheme="teal" onClick={onSignUp}>
                  Send OTP
                </Button>
              </>
            )}
            {currentStep === 2 && (
              <>
                <Text textAlign="center" mb={4}>
                  Enter the OTP sent to your phone.
                </Text>
                <HStack spacing={4} justify="center">
                  {Array.from({ length: otpLength }).map((_, index) => (
                    <Input
                      key={index}
                      id={`otp-input-${index}`}
                      size="sm"
                      maxLength={1}
                      value={otp[index] || ''}
                      onChange={(e) => handleChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      textAlign="center"
                    />
                  ))}
                </HStack>
                <br />
                <Button
                  colorScheme="green"
                  onClick={handleVerifyOtp} // Verify OTP
                >
                  Verify OTP
                </Button>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </>

            )}

            {currentStep === 3 && (
              <>
                <div style={{ marginLeft: '150px' }}>
                  <Button colorScheme="blue" onClick={handlePreviousShipments}>
                    Previous Shipments
                  </Button>
                  <Button colorScheme="green" onClick={handleNewShipment} ml={3}>
                    New Shipment
                  </Button>
                </div>
              </>
            )}
            {currentStep === 4 && (
              <Flex flexDirection="column">
                <Grid templateColumns="1fr 1fr" gap={4}>
                  {/* From Fields */}
                  <Flex flexDirection="column">
                    <FormControl mb={4} isInvalid={!!validationErrors.fromContactName}>
                      <FormLabel>Sender Name *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.fromContactName}
                        onChange={(e) => handleShippingInputChange(e, 'fromContactName')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.fromAddress1}>
                      <FormLabel>Origin/Sender Address *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.fromAddress1}
                        onChange={(e) => handleShippingInputChange(e, 'fromAddress1')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.fromCity}>
                      <FormLabel>City of Origin *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.fromCity}
                        onChange={(e) => handleShippingInputChange(e, 'fromCity')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.fromState}>
                      <FormLabel>State of Origin *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.fromState}
                        onChange={(e) => handleShippingInputChange(e, 'fromState')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.fromPostalCode}>
                      <FormLabel>Postal Code of Origin *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.fromPostalCode}
                        onChange={(e) => handleShippingInputChange(e, 'fromPostalCode')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.fromCountry}>
                      <FormLabel>Country of Origin *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.fromCountry}
                        onChange={(e) => handleShippingInputChange(e, 'fromCountry')}
                      />
                    </FormControl>
                    <FormControl mb={4}>
                      <FormLabel>Terminal</FormLabel>
                      <Select
                        size="sm"
                        value={shippingDetails.fromTerminal || "MISS,UTOR,BRA"} 
                        onChange={(e) => handleShippingInputChange(e, 'fromTerminal')}
                      >
                        <option value="MISS,UTOR,BRA">MISS,UTOR,BRA</option>
                      </Select>
                    </FormControl>
                  </Flex>

                  {/* To Fields */}
                  <Flex flexDirection="column">
                    <FormControl mb={4} isInvalid={!!validationErrors.toContactName}>
                      <FormLabel>Receiver Name *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.toContactName}
                        onChange={(e) => handleShippingInputChange(e, 'toContactName')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.toAddress1}>
                      <FormLabel>Destination/Receiver Address *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.toAddress1}
                        onChange={(e) => handleShippingInputChange(e, 'toAddress1')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.toCity}>
                      <FormLabel>City of Destination *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.toCity}
                        onChange={(e) => handleShippingInputChange(e, 'toCity')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.toState}>
                      <FormLabel>State of Destination *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.toState}
                        onChange={(e) => handleShippingInputChange(e, 'toState')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.toPostalCode}>
                      <FormLabel>Postal Code of Destination *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.toPostalCode}
                        onChange={(e) => handleShippingInputChange(e, 'toPostalCode')}
                      />
                    </FormControl>
                    <FormControl mb={4} isInvalid={!!validationErrors.toCountry}>
                      <FormLabel>Country of Destination *</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.toCountry}
                        onChange={(e) => handleShippingInputChange(e, 'toCountry')}
                      />
                    </FormControl>
                    <FormControl mb={4}>
                      <FormLabel>Terminal</FormLabel>
                      <Input
                        size="sm"
                        value={shippingDetails.toTerminal}
                        onChange={(e) => handleShippingInputChange(e, 'toTerminal')}
                      />
                    </FormControl>
                  </Flex>
                </Grid>

                <Flex flexDirection="column">
                  <FormControl mb={4}>
                    <FormLabel>Insurance of Carriage Yes/No</FormLabel>
                    <Select
                      size="sm"
                      value={shippingDetails.signatureRequired ? 'Yes' : 'No'}
                      onChange={(e) => handleShippingInputChange(e, 'signatureRequired')}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Select>
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Confirmation of Delivery Signature Required Yes/No</FormLabel>
                    <Select
                      size="sm"
                      value={shippingDetails.insuranceRequired ? 'Yes' : 'No'}
                      onChange={(e) => handleShippingInputChange(e, 'insuranceRequired')}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Select>
                  </FormControl>
                </Flex>
              </Flex>
            )}
            {currentStep === 5 && (
              <>                
                <Box>
                    <Flex justifyContent="space-between" mb={4}>
                      <Text fontSize="2xl" fontWeight="bold">
                        Enter Package Details                         
                      </Text>
                      
                      <Button
                        leftIcon={<AddIcon />}
                        colorScheme="teal"
                        onClick={addNewPackage}
                      >
                        Add Package
                      </Button>
                    </Flex>
                </Box>
                <Box>
                <Text fontSize="lg" fontWeight="bold" color="blue">
                        (If you have packages of different dims add individual packages with individual dims)
                      </Text>
                </Box>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>No of Packages</Th>
                      <Th>Package Type</Th>
                      <Th>Length (CM)</Th>
                      <Th>Width (CM)</Th>
                      <Th>Height (CM)</Th>
                      <Th>Dimension Unit</Th>
                      <Th>Weight per Pkg</Th>
                      <Th>Weight Unit</Th>
                      <Th>Inner Goods</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  {shippingDetails.packages.map((pkg, index) => (
                    <React.Fragment key={index}>
                      <Tr>
                        <Td>
                          <Input
                            size="sm"
                            type="number"
                            value={pkg.pieces}
                            onChange={(e) => handlePackageInputChange(e, index, 'pieces')}
                            min={0}
                            isInvalid={!!validationErrors[`package${index}_pieces`]}
                          />
                        </Td>
                        <Td>
                          <Select
                            size="sm"
                            value={pkg.piecesType}
                            onChange={(e) => handlePackageInputChange(e, index, 'piecesType')}
                            isInvalid={!!validationErrors[`package${index}_piecesType`]}
                          >
                            <option value="Box">Box</option>
                            <option value="Bag">Bag</option>
                            <option value="Envelope">Envelope</option>
                          </Select>
                        </Td>
                        <Td>
                          <Input
                            size="sm"
                            type="number"
                            value={pkg.sizeLength}
                            onChange={(e) => handlePackageInputChange(e, index, 'sizeLength')}
                            min={0}
                            isInvalid={!!validationErrors[`package${index}_sizeLength`]} 
                          />
                        </Td>
                        <Td>
                          <Input
                            size="sm"
                            type="number"
                            value={pkg.sizeWidth}
                            onChange={(e) => handlePackageInputChange(e, index, 'sizeWidth')}
                            min={0}
                            isInvalid={!!validationErrors[`package${index}_sizeWidth`]}
                          />
                        </Td>
                        <Td>
                          <Input
                            size="sm"
                            type="number"
                            value={pkg.sizeHeight}
                            onChange={(e) => handlePackageInputChange(e, index, 'sizeHeight')}
                            min={0}
                            isInvalid={!!validationErrors[`package${index}_sizeHeight`]}
                          />
                        </Td>
                        <Td>
                          <Select
                            size="sm"
                            value={pkg.sizeType}
                            onChange={(e) => handlePackageInputChange(e, index, 'sizeType')}
                            isInvalid={!!validationErrors[`package${index}_sizeType`]}
                          >
                            <option value={0}>CM</option>
                            <option value={1}>INCH</option>
                          </Select>
                        </Td>
                        <Td>
                          <Input
                            size="sm"
                            type="number"
                            value={pkg.weight}
                            onChange={(e) => handlePackageInputChange(e, index, 'weight')}
                            min={0}
                            isInvalid={!!validationErrors[`package${index}_weight`]}
                          />
                        </Td>
                        <Td>
                          <Select
                            size="sm"
                            value={pkg.weightType}
                            onChange={(e) => handlePackageInputChange(e, index, 'weightType')}
                            isInvalid={!!validationErrors[`package${index}_weightType`]}
                          >
                            <option value={0}>KG</option>
                            <option value={1}>LB</option>
                          </Select>
                        </Td>
                        <Td>
                          <IconButton
                            size="sm"
                            icon={expandedPackages[index] ? <ChevronDownIcon /> : <ChevronRightIcon />}
                            aria-label="Expand Inner Goods"
                            onClick={() => toggleExpanded(index)}
                          />
                        </Td>
                        <Td>
                          <IconButton
                            size="sm"
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            aria-label="Delete Package"
                            onClick={() => {
                              const updatedPackages = shippingDetails.packages.filter((_, idx) => idx !== index);
                              setShippingDetails({ ...shippingDetails, packages: updatedPackages });
                            }}
                          />
                        </Td>
                      </Tr>
                      {/* Inner Goods Row */}
                      {expandedPackages[index] && (
                        <Tr>
                          <Td colSpan={10}>
                            <Box mt={4} borderWidth="1px" borderRadius="md" p={4}>
                            <Flex justifyContent="space-between" mb={4}>
                              <Text fontWeight="bold" mb={4} fontSize="lg" color="teal.600">
                                Inner Goods
                              </Text>
                              <Button
                                leftIcon={<AddIcon />}
                                colorScheme="teal"
                                size="sm"
                                onClick={() => addItemToPackage(index)}
                              >
                                Add Item
                              </Button>
                              </Flex>
                              <Table variant="simple" size="sm">
                                <Thead>
                                  <Tr>
                                    <Th>Description</Th>
                                    <Th>HS Code</Th>
                                    <Th>Weight</Th>
                                    <Th>Weight Type</Th>
                                    <Th>Declared Value</Th>
                                    <Th>Quantity</Th>
                                    <Th>Actions</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                    {pkg.items.map((item, itemIndex) => (
                                      <Tr key={itemIndex}>
                                        <Td>
                                          <Input
                                            size="sm"
                                            value={item.description}
                                            onChange={(e) =>
                                              handleItemInputChange(e, index, itemIndex, 'description')
                                            }
                                            onBlur={() => {
                                              if (item.description.trim()) {
                                                handleFindHsCode(item.description, index, itemIndex);
                                              }
                                            }}
                                            isInvalid={
                                              !!validationErrors[`package${index}_item${itemIndex}_description`]
                                            }
                                          />
                                        </Td>
                                        <Td>
                                          <Input
                                            size="sm"
                                            value={item.hsCode}
                                            onChange={(e) =>
                                              handleItemInputChange(e, index, itemIndex, 'hsCode')
                                            }
                                            isInvalid={
                                              !!validationErrors[`package${index}_item${itemIndex}hsCode`]
                                            }
                                          />
                                        </Td>
                                        <Td>
                                          <Input
                                            size="sm"
                                            type="number"
                                            value={item.weight}
                                            onChange={(e) =>
                                              handleItemInputChange(e, index, itemIndex, 'weight')
                                            }
                                            min={0}
                                            isInvalid={
                                              !!validationErrors[`package${index}_item${itemIndex}_weight`]
                                            }
                                          />
                                        </Td>
                                        <Td>
                                          <Select
                                            size="sm"
                                            value={item.weightType}
                                            onChange={(e) =>
                                              handleItemInputChange(e, index, itemIndex, 'weightType')
                                            }
                                          >
                                            <option value={0}>KG</option>
                                            <option value={1}>LB</option>
                                          </Select>
                                        </Td>
                                        <Td>
                                          <Input
                                            size="sm"
                                            type="number"
                                            value={item.declaredValue}
                                            onChange={(e) =>
                                              handleItemInputChange(e, index, itemIndex, 'declaredValue')
                                            }
                                            min={0}
                                          />
                                        </Td>
                                        <Td>
                                          <Input
                                            size="sm"
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) =>
                                              handleItemInputChange(e, index, itemIndex, 'quantity')
                                            }
                                            min={1}
                                          />
                                        </Td>
                                        <Td>
                                          <IconButton
                                            icon={<DeleteIcon />}
                                            aria-label="Delete Item"
                                            colorScheme="red"
                                            size="sm"
                                            onClick={() => {
                                              const updatedPackages = [...shippingDetails.packages];
                                              updatedPackages[index].items.splice(itemIndex, 1);
                                              setShippingDetails({ ...shippingDetails, packages: updatedPackages });
                                            }}
                                          />
                                        </Td>
                                      </Tr>
                                    ))}
                                  </Tbody>
                              </Table>
                            </Box>
                          </Td>
                        </Tr>
                      )}
                    </React.Fragment>
                  ))}
                  </Tbody>
                </Table>
                <Button colorScheme="teal" onClick={handlePackageSubmit}>
                  Show Rates
                </Button>
                {showShippingRates && (
                  <>
                    <CurrentStep4 shippingRate={shippingRate} handleCreateBooking={handleCreateBooking}  />
                  </>
                )}
              </>
            )}
            {currentStep === 6 && bookingData && (
              <VStack spacing={4} align="start">
                <Heading as="h2" size="lg">Booking Successful</Heading>
                <Text><strong>Booking ID:</strong> {bookingData?.LoadNumber}</Text>
                <Text><strong>Carrier:</strong> {bookingData?.Carrier}</Text>
                <Text><strong>Sorting Code:</strong> {bookingData?.SortingCode}</Text>

                <Heading as="h3" size="md" mt={4}>Packages</Heading>
                {bookingData?.Packages && bookingData.Packages.length > 0 ? (
                  <>
                    {/* {bookingData.Packages.map((pkg) => (
                      <Box key={pkg.Id} p={4} borderWidth="1px" borderRadius="md" w="100%">
                        <Text><strong>Package ID:</strong> {pkg.Id}</Text>
                        <Text><strong>Tracking Number:</strong> {pkg.TrackingNumber.join(', ')}</Text>
                        <Flex mt={2} gap={4}>
                          <Button mt={2} colorScheme="teal" onClick={() => handlePrintBarcode(pkg.LabelZpl)}>Print Barcode</Button>
                          <Button mt={2} colorScheme="teal" onClick={() => onEmailBarcodeClick(pkg.LabelZpl)}>Email Barcode</Button>
                        </Flex>
                      </Box>
                    ))} */}
                    <Box p={4} borderWidth="1px" borderRadius="md" w="100%">
                      <Flex mt={2} gap={4}>
                        <Button mt={2} colorScheme="teal" onClick={() => handlePrintAllBarcodes(bookingData.Packages)}>Print Barcodes</Button>
                        <Button mt={2} colorScheme="teal" onClick={() => handlePrintAllBarcodes(bookingData.Packages)}>Email Barcodes</Button>
                      </Flex>
                    </Box>
                  </>
                ) : (
                  <Text color="red.500">No packages available for this booking.</Text>
                )}
                <Button colorScheme="blue" onClick={resetBookingDetails}>
                  Make Another Booking
                </Button>
              </VStack>
            )}

          </ModalBody>
          <ModalFooter>
            <HStack spacing={4}>
            // Assuming you have a variable isGuest to check if the user is a guest

              {currentStep > 0 && (
                <Button
                  onClick={() => {
                    if (isGuest && currentStep === 4) {
                      // If the user is a guest and currently at step 4, go back to step 2
                      setCurrentStep(2);
                    } else {
                      // Otherwise, decrement normally
                      setCurrentStep((prev) => prev - 1);
                    }
                  }}
                >
                  Back
                </Button>
              )}

              {(currentStep === 1 || currentStep >= 3) && (
                <Button
                  colorScheme="green"
                  onClick={handleClickNext}
                >
                  Next
                </Button>
              )}

              <Button
                onClick={() => {
                  setIsOpen(false); // Close the modal
                  window.location.reload(); // Refresh the page
                }}
              >
                Close
              </Button>

            </HStack>
          </ModalFooter>
          {/* Email Modal */}
          <Modal isOpen={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Email Barcode</ModalHeader>
              <ModalBody>
                <FormControl mb={4}>
                  <FormLabel>Email Address(es)</FormLabel>
                  <Input
                    placeholder="Enter email addresses (comma-separated)"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" onClick={handleSendEmails}>Send Emails</Button>
                <Button ml={3} onClick={() => setIsEmailModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </ModalContent>
      </Modal>
      <Toaster />
    </Box>
  );
};

export default ShippingForm;