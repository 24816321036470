import React from 'react';
import './Css/ShippingService.css'; // Ensure to create this CSS file for styling

// Import logos
import usLogo from '../assets/images/US.jpg'; 
import canLogo from '../assets/images/can.jpg'; 
import globalLogo from '../assets/images/global.jpg'; 
import amaLogo from '../assets/images/ama.jpg'; 
import returnsLogo from '../assets/images/return.jpg'; 

// Define the type for shipping options
interface ShippingOption {
    method: string;
    description: string;
    logo: string;
}

const ShippingService: React.FC = () => {
    const shippingOptions: ShippingOption[] = [
        {
            method: 'U.S Shipping',
            description: 'Fly My Package Express gives you the ability to compete with U.S. sellers, with access to discounted rates on USPS postage. Improve your company’s bottom-line today.',
            logo: usLogo,
        },
        {
            method: 'Canadian Shipping',
            description: 'Fly My Package Express offers competitive rates with our domestic shipping service, providing the lowest price on the market to ship anywhere in Canada.',
            logo: canLogo,
        },
        {
            method: 'International Shipping',
            description: 'Fly My Package Express works with APC Logistics and PostNL to bring you the fastest and most reliable shipping service to grow your market worldwide.',
            logo: globalLogo,
        },
        {
            method: 'Amazon FBA',
            description: 'Fly My Package Express helps Canadians take advantage of Amazon’s FBA (fulfilled by Amazon) service.',
            logo: amaLogo,
        },
        {
            method: 'Returns',
            description: 'Customer changed their mind? With Stallion Express, ship the item to our U.S. address and pick up in the GTA.',
            logo: returnsLogo,
        },
    ];

    return (
        <div className="shipping-service">
            <h2>Shipping Services for Every eCommerce Seller</h2>
            <p>Looking to grow your business to reach customers around the world? We’ve got you covered.</p>
            <div className="shipping-options">
                {shippingOptions.map((option, index) => (
                    <div key={index} className="shipping-option">
                        <img src={option.logo} alt={`${option.method} logo`} className="shipping-logo" />
                        <h3>{option.method}</h3>
                        <p>{option.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ShippingService;
