import React from 'react';
import './Css/Footer.css'; // Updated path for the CSS file
import logo192 from '../assets/images/logo192.png';
import locationIcon from '../assets/images/location.png';
import telephoneIcon from '../assets/images/telephone.png';
import envelopeIcon from '../assets/images/envelope.png';
import clockIcon from '../assets/images/clock.png';
 import facebookIcon from '../assets/images/facebook.png';
 import twitterIcon from '../assets/images/twitter.jpg';
import instaIcon from '../assets/images/insta.png';
import youtubeIcon from '../assets/images/youtube.png';
import linkedinIcon from '../assets/images/linkedin.png';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-info">
          <img src={logo192} alt="Company Logo" className="company-logo" />
          <p>Delivered shipping offers & host of logistic management services and dynamic & proactive innovative solutions with the best.</p>
          <div className="contact-info">
            <p><img src={locationIcon} alt="Location" className="icon" /> 2710 Britannia Rd E, Mississauga, ON L4W 1S9</p>
            <p><img src={telephoneIcon} alt="Phone" className="icon" /> (905) 671-4443 ext.1</p>
            <p><img src={envelopeIcon} alt="Email" className="icon" /> www.danata.com</p>
            <p><img src={clockIcon} alt="Time" className="icon" /> Mon-Sun: 9:00 AM - 7:00 PM</p>
          </div>
        </div>
        <div className="social-media">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={instaIcon} alt="Instagram" />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <img src={youtubeIcon} alt="YouTube" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
